import React, { useEffect, useState } from "react";
import Select from "react-select";
import { FormGroup, Input, Label } from "reactstrap";
import { customOption } from "../../constants/const";
import { ApiResponsibility } from "../config/responsibilityType/ApiResponsibilityType";
import { GetLanguageString } from "../helper/Components";
import SelectUnitForPermissions from "./SelectUnitForPermissions";
import { encryptStorage } from "../../constants/EncryptStorage";

const Permissions = ({ permissions, setPermissions, item, index }) => {
  const [selectUnit, setSelectUnit] = useState(item.selectUnit);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [selectResponsibilities, setSelectResponsibilities] = useState(
    item.selectResponsibilities
  );
  const [selectedUnit, setSelectedUnit] = useState({});

  useEffect(() => {
    setSelectUnit(item.selectUnit);
    setSelectResponsibilities(item.selectResponsibilities);
  }, [item]);

  const [responsibility, setResponsibility] = useState([]);
  useEffect(() => {
    ApiResponsibility()
      .getResponsibilitiesByLoggedInUnit(
        item?.unit?.value === 0 ? loggedInUnitId : item?.unit?.value
      )
      .then((res) => {
        setResponsibility(res.data);
        let tempValues = [...permissions];
        tempValues[index].responsibilities = res.data.filter((item1) =>
          tempValues[index].responsibilities.find(
            (item2) => item1.label === item2.label
          )
        );
        setPermissions(tempValues);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, [selectedUnit]);

  const handleSelectAllResponsibilities = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    let tempValues = [...permissions];
    tempValues[index].selectAllResponsibilities = v;
    tempValues[index].responsibilities = v ? responsibility : [];
    setPermissions(tempValues);
  };

  const handleSelectUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;

    setSelectUnit(v);

    let tempValues = [...permissions];
    tempValues[index].selectUnit = v;
    setPermissions(tempValues);
  };

  const handleSelectResponsibilities = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;

    setSelectResponsibilities(v);

    let tempValues = [...permissions];
    tempValues[index].selectResponsibilities = v;
    tempValues[index].selectAllResponsibilities = !v && false;
    tempValues[index].responsibilities = !v && [];
    setPermissions(tempValues);
  };

  const handleOnChangeResponsibilities = (record) => {
    let tempValues = [...permissions];
    tempValues[index].responsibilities = record;
    setPermissions(tempValues);
  };

  return (
    <div>
      <FormGroup check>
        <Label check for={"selectUnits" + index}>
          <Input
            id={"selectUnits" + index}
            className="big-checkbox m-r-sm"
            type="checkbox"
            name={"selectUnits" + index}
            checked={selectUnit}
            value={!selectUnit}
            onChange={handleSelectUnit}
          />
          <div className="set-label">
            {" "}
            {<GetLanguageString props="member_select_unit" />}
          </div>
        </Label>
      </FormGroup>
      <br></br>
      <SelectUnitForPermissions
        unitId={item?.unit?.value}
        isDisabled={!selectUnit}
        index={index}
        permissions={permissions}
        setPermissions={setPermissions}
        setSelectedUnit={setSelectedUnit}
        responsibilitySelected={selectResponsibilities}
      />
      <hr></hr>
      <FormGroup check inline>
        <Label check for={"selectResponsibilities" + index}>
          <Input
            id={"selectResponsibilities" + index}
            type="checkbox"
            name={"selectResponsibilities" + index}
            className="big-checkbox m-r-sm"
            checked={selectResponsibilities}
            value={!selectResponsibilities}
            onChange={handleSelectResponsibilities}
          />
          <div className="set-label">
            {" "}
            {<GetLanguageString props="member_select_responsibilities" />}
          </div>
        </Label>
      </FormGroup>
      <br></br>
      <br></br>
      <FormGroup check>
        <Label check for={"selectAllResponsibilities" + index}>
          <Input
            id={"selectAllResponsibilities" + index}
            type="checkbox"
            name={"selectAllResponsibilities" + index}
            checked={permissions[index].selectAllResponsibilities}
            value={!permissions[index].selectAllResponsibilities}
            onChange={handleSelectAllResponsibilities}
            disabled={!selectResponsibilities}
          />
          {<GetLanguageString props="documents_select_all_responsibilities" />}
        </Label>
      </FormGroup>
      <Select
        options={responsibility}
        value={item.responsibilities}
        className="basic-multi-select ur"
        components={{ Option: customOption }}
        isMulti
        isDisabled={!selectResponsibilities}
        classNamePrefix="select"
        isClearable={true}
        onChange={(res) => {
          handleOnChangeResponsibilities(res);
        }}
      />
    </div>
  );
};

export default Permissions;