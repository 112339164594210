import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { FaSquareMinus, FaSquarePlus } from "react-icons/fa6";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import swal from "sweetalert";
import {
  LoadingSpinner,
  isUrdu,
  removeDuplicates,
} from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { ApiDocumentType } from "../config/documentType/ApiDocumentType";
import { GetLanguageString } from "../helper/Components";
import { GetSelectedLanguage } from "../helper/Method";
import { ApiDocuments } from "./ApiDocuments";
import Permissions from "./PermissionsComponent";
import CreateableTags from "./CreateableTags";

const UploadFile = ({
  setUploadFileModal,
  folderId,
  documents,
  setDocuments,
  droppedFile,
  setDroppedFile,
  uploadRefreshDocuments,
}) => {
  const toggle = () => {
    setUploadFileModal(false);
    setDroppedFile(null);
  };

  const [loading, setLoading] = useState(false);
  const [documentsTypesDropdown, setDocumentsTypesDropdown] = useState([]);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [filePermissions, setFilePermissions] = useState(0);
  const [inheritFolderPermissions, setInheritFolderPermissions] = useState(2);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(
    droppedFile !== null ? droppedFile : ""
  );
  const [uploadFileValues, setUploadFileValues] = useState({
    uploadedFile: droppedFile !== null ? droppedFile : "",
    description: "",
    documentType: "",
    permissionsApplied: filePermissions === 1,
    inheritPermissionsFromParentFolder: inheritFolderPermissions === 2,
    permissions: [],
    tags: [],
  });

  if (folderId === 0) folderId = 1;

  const [permissions, setPermissions] = useState([
    {
      unit: { value: loggedInUnitId, label: "" },
      forChildUnits: false,
      responsibilities: [],
      selectUnit: true,
      selectResponsibilities: false,
      selectAllResponsibilities: false,
    },
  ]);

  const addPermission = () => {
    const tempValues = [
      {
        unit: { value: 0, label: "" },
        forChildUnits: false,
        responsibilities: [],
        selectUnit: true,
        selectResponsibilities: false,
        selectAllResponsibilities: false,
      },
      ...permissions,
    ];
    setPermissions(tempValues);
  };

  const removeObject = (id) => {
    let tempValues = [...permissions];
    tempValues.splice(id, 1);
    setPermissions(tempValues);
  };
  const handleFilePermissions = (e) => {
    const { value } = e.target;
    setFilePermissions(parseInt(value));
    setInheritFolderPermissions(0);
    setUploadFileValues({
      ...uploadFileValues,
      permissionsApplied: value === 1,
      inheritPermissionsFromParentFolder: false,
    });
  };
  const handleInheritFolderPermissions = (e) => {
    const { value } = e.target;
    setInheritFolderPermissions(parseInt(value));
    setFilePermissions(0);
    setUploadFileValues({
      ...uploadFileValues,
      inheritPermissionsFromParentFolder: value === 2,
      permissionsApplied: false,
    });
  };
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const memberName = encryptStorage.getItem("memberName");
  const unitName = encryptStorage.getItem("loggedInUnitName");
  const responsibiityName = encryptStorage.getItem("responsibilityName");

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const [errors, setErrors] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    ApiDocumentType()
      .getDocumentTypesForDropDowns()
      .then((res) => {
        setDocumentsTypesDropdown(res.data);
        setLoading(false);
      })
      .catch((err) => {
        swal({
          title: err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
        console.log(err.response?.data || err);
      });
  }, []);

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file !== null) {
      // Size check (25 MB in bytes)
      if (file.size > 26214400) {
        setErrors({
          ...errors,
          uploadedFile: t("file_size_limit_exceed_error"),
        });
        return;
      }

      // File type check (no executable files)
      const fileType = file.type || file.name.split(".").pop().toLowerCase();
      if (
        fileType === "exe" ||
        fileType === "msi" ||
        fileType === "application/x-msdownload"
      ) {
        setErrors({
          ...errors,
          uploadedFile: t("file_type_error"),
        });
        return;
      }

      setUploadedFile(file);
      setErrors({
        ...errors,
        uploadedFile: "",
      });
    }
  };

  const validate = () => {
    let temp = {};

    const fileType =
      uploadedFile !== ""
        ? uploadedFile?.type ||
          uploadedFile?.name.split(".").pop().toLowerCase()
        : "";
    temp.uploadedFile =
      uploadedFile === "" ? (
        <GetLanguageString props="file_error" />
      ) : uploadedFile.size > 26214400 ? (
        <GetLanguageString props="file_size_limit_exceed_error" />
      ) : fileType === "exe" ||
        fileType === "msi" ||
        fileType === "application/x-msdownload" ? (
        <GetLanguageString props="file_type_error" />
      ) : (
        ""
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleChangeDocumentType = (record) => {
    setUploadFileValues({
      ...uploadFileValues,
      documentType: record,
    });

    setErrors({
      ...errors,
      documentType: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUploadFileValues({
      ...uploadFileValues,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      setButtonDisable(true);

      const formData = new FormData();
      formData.append(
        "FileType",
        uploadFileValues.documentType === ""
          ? 0
          : uploadFileValues.documentType.value
      );
      formData.append(
        "Description",
        uploadFileValues.description === "" ? "^" : uploadFileValues.description
      );
      formData.append("ParentFolderId", folderId);
      formData.append("PermissionsApplied", filePermissions === 1);

      // Append Permissions as JSON string (convert array to string)
      formData.append(
        "Permissions",
        JSON.stringify(removeDuplicates(permissions))
      );

      // Append the file
      formData.append("file", uploadedFile);

      formData.append("tags", JSON.stringify(selectedTags));

      swal({
        title: t("file_in_progress"),
        icon: "success",
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        buttons: "OK",
      });
      const tempValues = [...documents];
      tempValues.push({
        name: uploadedFile.name,
        fileType: uploadedFile.type,
        uploaderName: memberName,
        uploaderResponsibility: responsibiityName,
        uploaderUnit: unitName,
        description: uploadFileValues.description,
        size: uploadedFile.size,
        fileStatus: "file_in_progress",
        createdDate: new Date(),
      });
      setDocuments(tempValues);
      toggle();

      ApiDocuments()
        .addDocument(formData)
        .then((res) => {
          uploadRefreshDocuments();
          toggle();
        })
        .catch((err) => {
          setLoading(false);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          toggle();
          console.log(err);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          size={filePermissions ? "lg" : "m"}
          style={{
            maxWidth: filePermissions ? "1080px" : "600px",
            width: "100%",
          }}
          isOpen
          className="inmodal"
          autoFocus={false}
        >
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="document_upload_file" />}
          </ModalHeader>
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              {!loading ? (
                <>
                  <Row>
                    <Col md={filePermissions ? "3" : "12"}>
                      {" "}
                      <FormGroup>
                        <Label>
                          {
                            <GetLanguageString props="member_member_detail_upload_documents" />
                          }
                          <span
                            style={{
                              fontStyle: "italic",
                              fontSize: 8,
                              color: "red",
                              marginLeft: 5,
                            }}
                          >
                            *maximum file size 25 mb
                          </span>
                        </Label>
                        {droppedFile !== null ? (
                          <Input
                            type="text"
                            value={uploadedFile.name}
                            readOnly
                          />
                        ) : (
                          <Input
                            type="file"
                            name="uploadedFile"
                            onChange={handleUpload}
                          />
                        )}
                        {errors?.uploadedFile && (
                          <div className="text-error">
                            {errors.uploadedFile}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={filePermissions ? "3" : "12"}>
                      <FormGroup>
                        <Label>
                          {" "}
                          {
                            <GetLanguageString props="member_member_detail_select_documents_type" />
                          }
                        </Label>
                        <Select
                          value={uploadFileValues.documentType}
                          options={documentsTypesDropdown}
                          className={
                            GetSelectedLanguage() === "ur"
                              ? "basic-multi-select ur no-drag"
                              : "basic-multi-select no-drag"
                          }
                          classNamePrefix="select"
                          onChange={(record) => {
                            handleChangeDocumentType(record);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={filePermissions ? "3" : "12"}>
                      <FormGroup>
                        <Label for="tag-selector">
                          {<GetLanguageString props="document_document_tags" />}
                        </Label>
                        <CreateableTags
                          selectedTags={selectedTags}
                          setSelectedTags={setSelectedTags}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={filePermissions ? "3" : "12"}>
                      {" "}
                      <FormGroup>
                        <Label>
                          {<GetLanguageString props="common_description" />}
                        </Label>
                        <Input
                          type="text"
                          name="description"
                          className={
                            isUrdu(uploadFileValues.description)
                              ? "ur no-drag"
                              : "no-drag"
                          }
                          value={uploadFileValues.description}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Label check for={"inheritFolderPermissions"}>
                        <Input
                          type="radio"
                          id="inheritFolderPermissions"
                          value={2}
                          name="inheritFolderPermissions"
                          checked={inheritFolderPermissions === 2}
                          onChange={handleInheritFolderPermissions}
                        />
                        &nbsp;&nbsp;
                        {
                          <GetLanguageString
                            props={"folder_document_inherit_permissions"}
                          />
                        }
                      </Label>
                    </Col>
                    <Col md="6">
                      <Label check for={"filePermissions"}>
                        <Input
                          type="radio"
                          id="filePermissions"
                          value={1}
                          name="filePermissions"
                          checked={filePermissions === 1}
                          onChange={handleFilePermissions}
                        />
                        &nbsp;&nbsp;
                        {
                          <GetLanguageString
                            props={"folder_document_apply_permissions"}
                          />
                        }
                      </Label>
                    </Col>
                  </Row>
                  {filePermissions === 1 && (
                    <>
                      <br></br>
                      <div className="ibox-content">
                        <Row>
                          <Col md="11"></Col>
                          <Col md="1" className="text-center">
                            <div>
                              <FaSquarePlus
                                color="green"
                                size={25}
                                style={{ cursor: "pointer" }}
                                onClick={addPermission}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          {permissions?.map((item, index) => {
                            return (
                              <>
                                <div>
                                  <br></br>
                                  <Permissions
                                    item={item}
                                    index={index}
                                    permissions={permissions}
                                    setPermissions={setPermissions}
                                  />
                                  <br></br>
                                </div>
                                {permissions.length !== 1 && (
                                  <Row>
                                    <Col md="11"></Col>
                                    <Col md="1" className="text-center">
                                      <div>
                                        <FaSquareMinus
                                          color="red"
                                          size={25}
                                          style={{ cursor: "pointer" }}
                                          onClick={() => removeObject(index)}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                )}
                                <div>
                                  {index + 1 !== permissions.length && (
                                    <hr
                                      style={{
                                        borderTop: "1px dashed #000",
                                        width: "50%",
                                        margin: "0 auto",
                                      }}
                                    />
                                  )}
                                  <br></br>
                                </div>
                              </>
                            );
                          })}
                        </Row>
                      </div>
                    </>
                  )}
                </>
              ) : (
                LoadingSpinner()
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button color="primary" disabled={buttonDisable} type="submit">
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default UploadFile;