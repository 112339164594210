import Lottie from "lottie-react";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch, BsTrash3Fill } from "react-icons/bs";
import { FaRegFileArchive } from "react-icons/fa";
import { FaFolderPlus, FaRegFileImage, FaRegFilePdf } from "react-icons/fa6";
import { FcFile, FcFolder, FcOpenedFolder, FcVideoCall } from "react-icons/fc";
import { FiDownload, FiUpload } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import { RiFileExcel2Line, RiFilePpt2Line } from "react-icons/ri";
import { TbFileTypeZip, TbHomeFilled } from "react-icons/tb";
import Select from "react-select";
import { Link } from "react-router-dom/cjs/react-router-dom";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Table,
  Tooltip,
} from "reactstrap";
import swal from "sweetalert";
import { encryptStorage } from "../../constants/EncryptStorage";
import {
  CheckPermission,
  LoadingSpinner,
  applyClassToCharacters,
  capitalizeFirstLetter,
  customMultiValue,
  customOption,
  isUrdu,
} from "../../constants/const";
import { GetLanguageString, NoResult } from "../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../helper/Method";
import AddNewFolder from "./AddNewFolder";
import { ApiDocuments, ApiFolders } from "./ApiDocuments";
import EditFile from "./EditFile";
import EditFolder from "./EditFolder";
import UploadFile from "./UploadFile";
import UploadStatus from "./UploadStatus";
import downloadingAnimation from "./downloadingIconAnimation.json";
import { PiBroomDuotone } from "react-icons/pi";

const Documents = () => {
  const { t } = useTranslation();
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [folderUnitId, setFolderUnitId] = useState(loggedInUnitId);
  const [unitFolderUnitId, setUnitFolderUnitId] = useState(0);
  const [folderId, setFolderId] = useState(0);
  const [editValue, setEditValue] = useState();
  const [hierarchyDisable, setHierarchyDisable] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [foldersBreadCrumbs, setFoldersBreadCrumbs] = useState([]);
  const [folderParentUnitIds, setFolderParentUnitIds] = useState([]);
  const [newFolderModal, setNewFolderModal] = useState(false);
  const [uploadFileModal, setUploadFileModal] = useState(false);
  const [editFolderModal, setEditFolderModal] = useState(false);
  const [editFileModal, setEditFileModal] = useState(false);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [detailBox, setDetailBox] = useState(false);
  const [documentTagsList, setDocumentTagsList] = useState([]);
  const [droppedFile, setDroppedFile] = useState(null);
  const [error, setError] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [clearSearch, setClearSearch] = useState(false);
  const [resultFromSearch, setResultFromSearch] = useState(false);
  const searchBoxRef = useRef(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Check the condition
      if (
        !folderParentUnitIds.includes(unitFolderUnitId) &&
        !resultFromSearch
      ) {
        // If condition is true, continue with the file handling logic
        const uploadedFile = acceptedFiles[0];
        // File size limit (26 MB = 26 * 1024 * 1024 bytes)
        const maxSizeBytes = 26214400; // 26 MB in bytes

        if (uploadedFile.size > maxSizeBytes) {
          setError(t("file_size_limit_exceed_error"));
          clearErrorAfterDelay();
          return;
        }

        // File type check (no executable files)
        const fileType =
          uploadedFile.type || uploadedFile.name.split(".").pop().toLowerCase();
        if (
          fileType === "exe" ||
          fileType === "msi" ||
          fileType === "application/x-msdownload"
        ) {
          setError(t("file_type_error"));
          clearErrorAfterDelay();
          return;
        }

        setDroppedFile(uploadedFile);
        setError(null); // Reset error state
        setUploadFileModal(true);
      }
      // If the condition is false, the function will exit and no action is taken
    },
    [t, folderParentUnitIds, unitFolderUnitId, resultFromSearch]
  );

  const clearErrorAfterDelay = () => {
    setTimeout(() => {
      setError(null);
    }, 3000); // 5000 milliseconds = 5 seconds
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (action, index) => {
    setTooltipOpen((prev) => ({
      ...prev,
      [`${action}-${index}`]: !prev[`${action}-${index}`],
    }));
  };

  useEffect(() => {
    ApiDocuments()
      .GetDocumentTags()
      .then((res) => {
        setDocumentTagsList(res.data);
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
  }, []);

  useEffect(() => {
    setDetailBox(false);
    setLoading(true);
    setHierarchyDisable(true);
    setFolderParentUnitIds([]);
    ApiFolders()
      .getFolderHierarchy(folderUnitId, folderId)
      .then((res) => {
        setFolderParentUnitIds(res.data.parentUnitIds);
        setFoldersBreadCrumbs(res.data.hierarchy);
        setResultFromSearch(res.data.resultFromSearch);
        setDetailBox(false);
        if (res.data.length > 0)
          setFolderId(
            res.data.hierarchy[res.data.hierarchy.length - 1]?.folderId
          );
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setHierarchyDisable(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
    // eslint-disable-next-line
  }, [folderId]);

  useEffect(() => {
    setHierarchyDisable(true);
    setLoading(true);
    ApiDocuments()
      .getDocuments({
        folderUnitId,
        folderId,
        searchString: searchTerm,
        tags: selectedTags,
      })
      .then((res) => {
        setDocuments(res.data.files);
        setHoveredRowIndex(null);
        setHierarchyDisable(false);
        setResultFromSearch(res.data.resultFromSearch);

        if (folderId !== 0) setLoading(false);
        if (foldersBreadCrumbs.length <= 0) {
          setFolderId(res.data.folderId);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setHierarchyDisable(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
    // eslint-disable-next-line
  }, [folderId, refresh]);

  const uploadRefreshDocuments = () => {
    setHierarchyDisable(true);
    ApiDocuments()
      .getDocuments({
        folderUnitId,
        folderId,
        searchString: searchTerm,
        tags: selectedTags,
      })
      .then((res) => {
        setDocuments(res.data.files);
        setHierarchyDisable(false);
        setHoveredRowIndex(null);
      })
      .catch((err) => {
        console.log(err);
        setHierarchyDisable(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
  };

  const [isDownloading, setIsDownloading] = useState(
    Array(documents?.length).fill(false)
  );

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleRowClick = (e, index) => {
    if (!e.target.closest(".unclickable-column")) {
      setSelectedRowIndex(index === selectedRowIndex ? null : index);
    }
  };

  const showIcon = (index) => {
    return hoveredRowIndex === index || selectedRowIndex === index;
  };

  const formatSize = (sizeInBytes) => {
    const units = ["bytes", "KB", "MB", "GB", "TB"];
    let size = sizeInBytes;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return `${size.toFixed(2)} ${units[unitIndex]}`;
  };
  const handleOnDelete = async (id, type) => {
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      // <-- Notice "async" here
      if (!willDelete) return; // Exit if user cancels

      const isFolder = type.includes("folder");
      const api = isFolder ? ApiFolders() : ApiDocuments();
      const successMessageKey = isFolder
        ? "document_delete_folder_success"
        : "document_delete_file_success";

      try {
        setLoading(true);

        await api.delete(id);

        swal({
          title: t(successMessageKey),
          className: GetSelectedLanguage() === "en" ? "" : "ur",
          icon: "success",
          buttons: "OK",
        });

        setRefresh(!refresh);
      } catch (err) {
        console.error(err);
        swal({
          title: err.response?.data,
          icon: "error",
          buttons: "OK",
        });
      } finally {
        setLoading(false);
      }
    });
  };
  const getUnitName = (unitName) => {
    return (
      <ol
        className="breadcrumb"
        style={{
          backgroundColor: "transparent",
        }}
      >
        {unitName?.split("/").map((item, index) => {
          return (
            <li
              className={
                GetSelectedLanguage() === "ur"
                  ? "breadcrumb-item-ur"
                  : "breadcrumb-item"
              }
              key={index}
            >
              <span>{applyClassToCharacters(item, "ur")}</span>
            </li>
          );
        })}
      </ol>
    );
  };

  const handleDownload = async (fileId, index) => {
    const updateLoadingState = (isLoading) => {
      setIsDownloading((prevStates) => {
        const newLoadingStates = [...prevStates];
        newLoadingStates[index] = isLoading;
        return newLoadingStates;
      });
    };

    updateLoadingState(true);

    try {
      const response = await ApiDocuments().downloadDocument(fileId);

      if (response.status === 200) {
        const modifiedLink = `${response.data.downloadLink}&confirm=t`;

        // Open link in the current tab/window:
        window.location.href = modifiedLink;
      } else {
        swal({
          title: t(response.data),
          icon: "error",
          buttons: "OK",
        });
        console.error("Error getting download link:", response.data);
      }
    } catch (error) {
      swal({
        title: error.message,
        icon: "error",
        buttons: "OK",
      });
    } finally {
      updateLoadingState(false);
    }
  };

  const handleBreadCrumbs = (item) => {
    if (!loading) {
      setSearchTerm("");
      setSelectedTags([]);
      setFolderId(item.folderId);
      setFolderUnitId(item.folderUnitId ? item.folderUnitId : 0);
      if (item.folderUnitId) setUnitFolderUnitId(item.folderUnitId);
    }
  };

  const ButtonsComponent = (className) => {
    return CheckPermission("CanAddDocuments") ? (
      <ButtonGroup className={className}>
        <Button color="white" onClick={() => setNewFolderModal(true)}>
          <FaFolderPlus size={15} color="#FFCA28" />
          &nbsp; <GetLanguageString props={"document_new_folder"} />
        </Button>
        &nbsp;&nbsp;
        <Button
          color="white"
          onClick={() => {
            setUploadFileModal(true);
          }}
        >
          {" "}
          <FiUpload size={15} color="#35A29F" />
          &nbsp;
          <GetLanguageString props={"document_upload_file"} />
        </Button>
      </ButtonGroup>
    ) : null;
  };

  const getFileIcon = (fileType) => {
    const iconStyle = { marginRight: 5, marginBottom: 3, size: 16 };

    if (fileType.includes("folder")) {
      return <FcFolder size={iconStyle.size} style={iconStyle} />;
    } else if (fileType.includes("pdf")) {
      return (
        <FaRegFilePdf size={iconStyle.size} color="red" style={iconStyle} />
      );
    } else if (
      fileType.includes("jpg") ||
      fileType.includes("png") ||
      fileType.includes("jpeg")
    ) {
      return (
        <FaRegFileImage
          size={iconStyle.size}
          color="#35A29F"
          style={iconStyle}
        />
      );
    } else if (fileType.includes("pptx")) {
      return (
        <RiFilePpt2Line
          size={iconStyle.size}
          color="#B94623"
          style={iconStyle}
        />
      );
    } else if (fileType.includes("mp4")) {
      return (
        <FcVideoCall size={iconStyle.size} color="#5C6E91" style={iconStyle} />
      );
    } else if (fileType.includes("x-zip-compressed")) {
      return (
        <TbFileTypeZip
          size={iconStyle.size}
          color="#5A509B"
          style={iconStyle}
        />
      );
    } else if (fileType.includes("x-compressed")) {
      return (
        <FaRegFileArchive
          size={iconStyle.size}
          color="#5A509B"
          style={iconStyle}
        />
      );
    } else if (fileType.includes("spreadsheet")) {
      return (
        <RiFileExcel2Line
          size={iconStyle.size}
          color="#009440"
          style={iconStyle}
        />
      );
    } else {
      return <FcFile size={iconStyle.size} style={iconStyle} />;
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    // Call handleSearch whenever searchTerm or selectedTags changes
    if (
      searchTerm === "" &&
      selectedTags.length === 0 &&
      resultFromSearch &&
      clearSearch
    ) {
      handleSearch();
    }
    setClearSearch(false);
    // eslint-disable-next-line
  }, [searchTerm, selectedTags, clearSearch]);

  const handleClearSearch = () => {
    if (searchTerm !== "" || selectedTags.length !== 0) {
      setSearchTerm("");
      setSelectedTags([]);
      setClearSearch(true);
    }
  };

  const handleSearch = () => {
    setDetailBox(false);
    setLoading(true);
    ApiDocuments()
      .getDocuments({
        folderUnitId,
        folderId,
        searchString: searchTerm,
        tags: selectedTags,
      })
      .then((res) => {
        setDocuments(res.data.files);
        setHoveredRowIndex(null);
        setHierarchyDisable(false);
        setResultFromSearch(res.data.resultFromSearch);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "20px",
      minHeight: "30px",
      border: state.isFocused ? "1px solid #1AB394" : "",
      boxShadow: state.isFocused ? "0 0 0 1px #1AB394" : "none",
      "&:hover": {
        border: "1px solid #1AB394",
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "15px",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
      backgroundColor: state.isSelected ? "#1AB394" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: "#BAE8DE",
        color: "black",
      },
    }),
  };

  // Handle input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handleSearchTagsChange = (newValue) => {
    setSelectedTags(newValue || []);
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      {newFolderModal ? (
        <AddNewFolder
          setNewFolderModal={setNewFolderModal}
          parentFolderId={folderId}
          setRefresh={setRefresh}
        />
      ) : null}
      {editFolderModal ? (
        <EditFolder
          setEditFolderModal={setEditFolderModal}
          parentFolderId={folderId}
          setRefresh={setRefresh}
          folderId={editValue}
        />
      ) : null}
      {editFileModal ? (
        <EditFile
          setEditFileModal={setEditFileModal}
          parentFolderId={folderId}
          setRefresh={setRefresh}
          fileId={editValue}
        />
      ) : null}
      {uploadFileModal ? (
        <UploadFile
          setUploadFileModal={setUploadFileModal}
          folderId={folderId}
          uploadRefreshDocuments={uploadRefreshDocuments}
          documents={documents}
          setDocuments={setDocuments}
          droppedFile={droppedFile}
          setDroppedFile={setDroppedFile}
        />
      ) : null}
      <div className="ibox">
        <div className="ibox-title">
          <Row>
            <Col md="3">
              <h5 style={{ paddingTop: 10 }}>
                <GetLanguageString props={"sidebar_documents"} />
              </h5>
            </Col>
            <Col md="4">
              <div
                ref={searchBoxRef}
                // className={GetCustomStyles().btn_group_style}
                style={{ position: "relative", width: "530px" }}
              >
                <InputGroup>
                  <InputGroupText
                    className="position-absolute bg-transparent border-0 text-muted"
                    style={{
                      zIndex: 4,
                      left: GetSelectedLanguage() === "ur" ? "unset" : "5px", // Adjust search icon position for RTL
                      right: GetSelectedLanguage() === "ur" ? "5px" : "unset", // Adjust for RTL
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <BsSearch size={17} />
                  </InputGroupText>
                  <Input
                    type="search"
                    placeholder={
                      GetSelectedLanguage() === "ur"
                        ? "ڈھونڈیں"
                        : "Search Documents/Folders"
                    }
                    className={isUrdu(searchTerm) ? "ur" : ""}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyDown}
                    style={{
                      textAlign:
                        GetSelectedLanguage() === "ur" ? "right" : "left",
                      direction: GetSelectedLanguage() === "ur" ? "rtl" : "ltr",
                      height: "37px",
                      borderRadius: "20px",
                      padding:
                        GetSelectedLanguage() === "ur"
                          ? "12px 45px 12px 16px"
                          : "12px 16px 12px 45px",
                      "&:hover": {
                        border: "1px solid #1AB394",
                      },
                    }}
                  />
                </InputGroup>

                {/* Dropdown for search results */}
                {/* {isDropdownOpen && searchTerm && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: GetSelectedLanguage() === "ur" ? "unset" : "0", // Adjust dropdown position for RTL
                      right: GetSelectedLanguage() === "ur" ? "0" : "unset", // Adjust for RTL
                      borderRadius: "20px",
                      backgroundColor: "white",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
                      zIndex: 1000,
                      width: "540px",
                      maxHeight: "300px",
                      overflowY: "auto",
                      marginTop: "5px",
                    }}
                  >
                    {!loadingSearch ? (
                      searchResults.length > 0 ? (
                        searchResults.map((result) => (
                          <div
                            key={result.id}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "10px",
                              borderBottom: "1px solid #eee",
                              cursor: "pointer",
                              color: "#333",
                              direction:
                                GetSelectedLanguage() === "ur" ? "rtl" : "ltr", // Set direction for result item
                            }}
                            onMouseEnter={(e) =>
                              (e.currentTarget.style.backgroundColor =
                                "#BAE8DE")
                            }
                            onClick={(e) => {
                              if (result.fileType.includes("folder")) {
                                setFolderId(result.id);
                                setSearchString(null);
                                setSearchTerm("");
                                setFolderUnitId(
                                  result.folderUnitId ? result.folderUnitId : 0
                                );
                                if (result.folderUnitId)
                                  setUnitFolderUnitId(result.folderUnitId);
                                setIsDropdownOpen(false);
                                setLoadingSearch(false);
                              }
                            }}
                            onMouseLeave={(e) =>
                              (e.currentTarget.style.backgroundColor = "#fff")
                            }
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {getFileIcon(result.fileType)}&nbsp;
                              <span>
                                {applyClassToCharacters(result.name, "ur")} -{" "}
                                {applyClassToCharacters(
                                  result.description,
                                  "ur"
                                )}
                              </span>
                            </div>
                          </div>
                        ))
                      ) : (
                        <NoResult />
                      )
                    ) : (
                      LoadingSpinner()
                    )}
                  </div>
                )} */}
              </div>
            </Col>
            <Col md="3">
              {" "}
              <Select
                styles={customStyles}
                options={documentTagsList}
                components={{
                  Option: customOption,
                  MultiValue: customMultiValue,
                }}
                value={selectedTags}
                isMulti
                onKeyDown={handleKeyDown}
                onChange={handleSearchTagsChange}
                className={
                  GetSelectedLanguage() === "ur"
                    ? "basic-multi-select ur no-drag"
                    : "basic-multi-select no-drag"
                }
                placeholder={GetSelectedLanguage() === "ur" ? "ٹیگز" : "Tags"}
                classNamePrefix="select"
              />
            </Col>
            <Col md="2">
              <ButtonGroup className={GetCustomStyles().btn_group_style}>
                <Button
                  color="default"
                  size="m"
                  style={{ width: "120px" }}
                  onClick={handleSearch}
                >
                  <BsSearch size={14} style={{ marginBottom: 2 }} />
                  &nbsp;
                  <GetLanguageString props={"common_search"} />
                </Button>
                &nbsp;&nbsp;
                <Button
                  color="default"
                  size="m"
                  disabled={searchTerm === "" && selectedTags.length === 0}
                  style={{ width: "120px" }}
                  onClick={handleClearSearch}
                >
                  <PiBroomDuotone size={14} style={{ marginBottom: 2 }} />
                  &nbsp;
                  <GetLanguageString props={"clear_search"} />
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </div>
        <div className="ibox-content">
          <Row>
            <Col md="10">
              <ol className="breadcrumb" style={{ marginTop: 8 }}>
                {foldersBreadCrumbs?.map((item, index) => {
                  return (
                    <li
                      className={
                        GetSelectedLanguage() === "ur"
                          ? "breadcrumb-item-ur"
                          : "breadcrumb-item"
                      }
                      key={index}
                      onClick={() => {
                        handleBreadCrumbs(item);
                      }}
                    >
                      <Link
                        to="#"
                        style={{
                          cursor: hierarchyDisable ? "default" : "pointer",
                        }}
                      >
                        {index === 0 ? (
                          <TbHomeFilled
                            size={14}
                            color={
                              foldersBreadCrumbs.length - 1 === index
                                ? "#18A689"
                                : "black"
                            }
                          />
                        ) : index === foldersBreadCrumbs.length - 1 ? (
                          <FcOpenedFolder size={16} />
                        ) : (
                          <FcFolder size={16} />
                        )}
                        &nbsp;
                        <span
                          className={isUrdu(item.folderName) ? "ur" : ""}
                          style={{
                            display: "inline-block",
                            fontWeight:
                              foldersBreadCrumbs.length - 1 === index
                                ? 600
                                : 500,
                            color:
                              foldersBreadCrumbs.length - 1 === index
                                ? "#18A689"
                                : "black",
                          }}
                        >
                          {item.folderName}
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ol>
            </Col>
            {documents.length !== 0 &&
            !folderParentUnitIds.includes(unitFolderUnitId) &&
            !resultFromSearch ? (
              <Col md="2">
                {ButtonsComponent(GetCustomStyles().unit_btn_style)}
              </Col>
            ) : null}
          </Row>
          {!loading ? (
            documents.length !== 0 ? (
              <div
                {...getRootProps()}
                style={{ position: "relative", marginTop: "20px" }}
              >
                <input {...getInputProps()} />
                <Row>
                  <Col md={detailBox ? "9" : "12"}>
                    <Table responsive hover bordered>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            <GetLanguageString props={"common_name"} />
                          </th>
                          <th>
                            <GetLanguageString
                              props={
                                "member_member_detail_document_upload_date"
                              }
                            />
                          </th>
                          {resultFromSearch ? (
                            <th>
                              <GetLanguageString props={"folder_path"} />
                            </th>
                          ) : null}
                          <th>
                            <GetLanguageString
                              props={"settings_document_type"}
                            />
                          </th>
                          <th>
                            <GetLanguageString
                              props={"member_member_detail_document_size"}
                            />
                          </th>
                          <th>
                            <GetLanguageString
                              props={"dashboard_event_page_status"}
                            />
                          </th>
                          <th className="unclickable-column"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {documents.map((file, index) => {
                          return (
                            <tr
                              key={index}
                              onMouseEnter={() => handleMouseEnter(index)}
                              onMouseLeave={handleMouseLeave}
                              style={{
                                backgroundColor:
                                  selectedRowIndex === index ||
                                  hoveredRowIndex === index
                                    ? "#DBECE6"
                                    : "white",
                                cursor: "default",
                              }}
                              onDoubleClick={(e) => {
                                if (
                                  !e.target.closest(".unclickable-column") &&
                                  file.fileType.includes("folder")
                                ) {
                                  setFolderId(file.id);
                                  setSearchTerm("");
                                  setSelectedTags([]);
                                  setFolderUnitId(
                                    file.folderUnitId ? file.folderUnitId : 0
                                  );
                                  if (file.folderUnitId)
                                    setUnitFolderUnitId(file.folderUnitId);
                                }
                              }}
                              onClick={(e) => {
                                handleRowClick(e, index);
                                if (
                                  !file.fileType.includes("folder") &&
                                  !e.target.closest(".unclickable-column")
                                ) {
                                  setSelectedFile(file);
                                  setDetailBox(true);
                                } else setDetailBox(false);
                              }}
                            >
                              <td>{index + 1}</td>
                              <td style={{ userSelect: "none" }}>
                                {getFileIcon(file.fileType)}
                                &nbsp;
                                {applyClassToCharacters(file.name, "ur")}&nbsp;
                                {file.isUnitDeleted && (
                                  <GetLanguageString
                                    props={"folder_unit_deleted"}
                                  />
                                )}
                              </td>
                              <td className={"en"}>
                                {moment(file.createdDate)
                                  .locale(GetSelectedLanguage())
                                  .format("DD/MM/YYYY")}{" "}
                                &nbsp;
                                {GetSelectedLanguage() === "ur"
                                  ? moment(file.createdDate)
                                      .locale(GetSelectedLanguage())
                                      .format("a\u00a0h:mm")
                                  : moment(file.createdDate)
                                      .locale(GetSelectedLanguage())
                                      .format("h:mm a")}
                              </td>
                              {resultFromSearch ? (
                                <td>{getUnitName(file.folderPath, index)}</td>
                              ) : null}
                              <td>
                                {file.documentType ? file.documentType : "-"}
                              </td>
                              <td>{file.size ? formatSize(file.size) : "-"}</td>
                              <td>
                                <UploadStatus
                                  fileStatus={file.fileStatus}
                                  jobId={file.fileUploadBackgroundJobId}
                                  uploadRefreshDocuments={
                                    uploadRefreshDocuments
                                  }
                                  fileId={file.id}
                                />
                              </td>
                              <td
                                className="text-center unclickable-column"
                                style={{ width: "130px" }}
                              >
                                {file.driveId ? (
                                  <div>
                                    {CheckPermission("CanDownloadDocuments") &&
                                    file.folderUnitId == null ? (
                                      !isDownloading[index] ? (
                                        <FiDownload
                                          size={18}
                                          id={`downloadIcon-${index}`}
                                          style={{ outline: "none", cursor: "pointer" }}
                                          color={
                                            showIcon(index) &&
                                            !file.fileType.includes("folder") &&
                                            file.fileStatus === "file_uploaded"
                                              ? "#35A29F"
                                              : "white"
                                          }
                                          onClick={() => {
                                            !file.fileType.includes("folder") &&
                                              file.fileStatus ===
                                                "file_uploaded" &&
                                              handleDownload(
                                                file.driveId,
                                                index
                                              );
                                          }}
                                        />
                                      ) : (
                                        <Lottie
                                          id={`downloadIcon-${index}`}
                                          animationData={downloadingAnimation}
                                          loop={true}
                                          autoplay={true}
                                          style={{
                                            width: "38px",
                                            height: "38px",
                                            marginBottom: -37,
                                            marginTop: -7,
                                            marginLeft: 35,
                                          }}
                                        />
                                      )
                                    ) : null}
                                    &nbsp;&nbsp;
                                    {CheckPermission("CanEditDocuments") &&
                                    file.folderUnitId == null &&
                                    !isDownloading[index] &&
                                    file.canChangePermissions ? (
                                      <BiSolidEdit
                                        size={20}
                                        id={`editIcon-${index}`}
                                        style={{ outline: "none", cursor: "pointer"  }}
                                        color={
                                          showIcon(index) ? "#35A29F" : "white"
                                        }
                                        onClick={() => {
                                          setEditValue(file.id);
                                          file.fileType.includes("folder")
                                            ? setEditFolderModal(true)
                                            : setEditFileModal(true);
                                        }}
                                      />
                                    ) : null}
                                    &nbsp;&nbsp;
                                    {CheckPermission("CanDeleteDocuments") &&
                                    file.folderUnitId == null &&
                                    !isDownloading[index] &&
                                    file.canChangePermissions ? (
                                      <BsTrash3Fill
                                        id={`deleteIcon-${index}`}
                                        style={{ outline: "none",  cursor: "pointer"   }}
                                        size={17}
                                        color={
                                          showIcon(index) ? "#35A29F" : "white"
                                        }
                                        onClick={() => {
                                          handleOnDelete(
                                            file.id,
                                            file.fileType
                                          );
                                        }}
                                      />
                                    ) : null}
                                    {
                                      //Tooltips for action buttons
                                      <>
                                        {CheckPermission(
                                          "CanDownloadDocuments"
                                        ) && file.folderUnitId == null
                                          ? document.getElementById(
                                              `deleteIcon-${index}`
                                            ) &&
                                            file.fileType !== "folder" && (
                                              <Tooltip
                                                isOpen={
                                                  tooltipOpen[
                                                    `download-${index}`
                                                  ] || false
                                                }
                                                target={`downloadIcon-${index}`}
                                                toggle={() =>
                                                  toggleTooltip(
                                                    "download",
                                                    index
                                                  )
                                                }
                                              >
                                                <GetLanguageString
                                                  props={
                                                    "member_add_rafiq_request_download_document"
                                                  }
                                                />
                                              </Tooltip>
                                            )
                                          : null}

                                        {CheckPermission("CanEditDocuments") &&
                                        file.canChangePermissions &&
                                        file.folderUnitId == null
                                          ? document.getElementById(
                                              `editIcon-${index}`
                                            ) && (
                                              <Tooltip
                                                isOpen={
                                                  tooltipOpen[
                                                    `edit-${index}`
                                                  ] || false
                                                }
                                                target={`editIcon-${index}`}
                                                toggle={() =>
                                                  toggleTooltip("edit", index)
                                                }
                                              >
                                                <GetLanguageString
                                                  props={"common_edit"}
                                                />
                                              </Tooltip>
                                            )
                                          : null}

                                        {CheckPermission(
                                          "CanDeleteDocuments"
                                        ) &&
                                        file.folderUnitId == null &&
                                        file.canChangePermissions
                                          ? document.getElementById(
                                              `deleteIcon-${index}`
                                            ) && (
                                              <Tooltip
                                                isOpen={
                                                  tooltipOpen[
                                                    `delete-${index}`
                                                  ] || false
                                                }
                                                target={`deleteIcon-${index}`}
                                                toggle={() =>
                                                  toggleTooltip("delete", index)
                                                }
                                              >
                                                <GetLanguageString
                                                  props={"common_delete"}
                                                />
                                              </Tooltip>
                                            )
                                          : null}
                                      </>
                                    }
                                  </div>
                                ) : null}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                  {detailBox ? (
                    <Col md={detailBox ? "3" : "0"}>
                      <div className="p-0">
                        <Row className={GetCustomStyles().unit_btn_style}>
                          <IoIosClose
                            size={25}
                            onClick={() => {
                              setDetailBox(false);
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </Row>
                        <Row className={"text-center"}>
                          {" "}
                          {selectedFile?.fileType.includes("folder") ? (
                            <FcFolder size={80} />
                          ) : selectedFile?.fileType.includes("pdf") ? (
                            <FaRegFilePdf size={80} color="red" />
                          ) : selectedFile?.fileType.includes("jpg") ||
                            selectedFile?.fileType.includes("png") ||
                            selectedFile?.fileType.includes("jpeg") ? (
                            <FaRegFileImage size={80} color="#35A29F" />
                          ) : selectedFile?.fileType.includes("pptx") ? (
                            <RiFilePpt2Line size={80} color="#B94623" />
                          ) : selectedFile?.fileType.includes("mp4") ? (
                            <FcVideoCall size={80} color="#5C6E91" />
                          ) : selectedFile?.fileType.includes(
                              "x-zip-compressed"
                            ) ? (
                            <TbFileTypeZip size={80} color="#5A509B" />
                          ) : selectedFile?.fileType.includes(
                              "x-compressed"
                            ) ? (
                            <FaRegFileArchive size={80} color="#5A509B" />
                          ) : selectedFile?.fileType.includes("spreadsheet") ? (
                            <RiFileExcel2Line size={80} color="#009440" />
                          ) : (
                            <FcFile size={80} />
                          )}
                        </Row>
                        <Row>
                          <h4 className="text-center">
                            {applyClassToCharacters(selectedFile?.name, "ur")}
                          </h4>
                        </Row>
                        <Row>
                          {t("common_type") +
                            " : " +
                            capitalizeFirstLetter(selectedFile?.fileType)}
                        </Row>
                        <Row>
                          <Col md="1" className={"p-0"}>
                            <GetLanguageString
                              props={"member_member_detail_document_size"}
                            />
                            {" : "}
                          </Col>
                          <Col md="5">
                            {selectedFile?.size
                              ? formatSize(selectedFile?.size)
                              : "-"}
                          </Col>
                        </Row>

                        {!selectedFile?.fileType.includes("folder") && (
                          <>
                            <Row>
                              <Col md="3" className={"p-0"}>
                                <GetLanguageString
                                  props={"common_description"}
                                />
                                {" : "}
                              </Col>
                              <Col md="9" className={"p-0"}>
                                {applyClassToCharacters(
                                  selectedFile?.description,
                                  "ur"
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col md="4" className={"p-0"}>
                                <GetLanguageString
                                  props={"document_uploader_name"}
                                />
                                {" : "}
                              </Col>
                              <Col md="8" className={"p-0 ur"}>
                                {selectedFile?.uploaderName}
                              </Col>
                            </Row>
                            <Row>
                              <Col md="5" className={"p-0"}>
                                <GetLanguageString
                                  props={"document_uploader_responsibility"}
                                />
                                {" : "}
                              </Col>
                              <Col md="7" className={"p-0 ur"}>
                                {selectedFile?.uploaderResponsibility}
                              </Col>
                            </Row>
                            <Row>
                              <Col md="4" className={"p-0"}>
                                <GetLanguageString
                                  props={"document_uploader_unit"}
                                />
                                {" : "}
                              </Col>
                              <Col md="5" className={"p-0"}>
                                {getUnitName(selectedFile?.uploaderUnit)}
                              </Col>
                            </Row>{" "}
                            <Row>
                              <Col md="3" className={"p-0"}>
                                <GetLanguageString props={"tags"} />
                                {" : "}
                              </Col>
                              <Col md="9" className={"p-0"}>
                                {selectedFile?.tags.map((item, index) => {
                                  return (
                                    <>
                                      <Button
                                        key={index}
                                        color="primary"
                                        size="xs"
                                        className={isUrdu(item) ? "ur" : ""}
                                        disabled
                                      >
                                        {item}
                                      </Button>
                                      <>&nbsp;</>
                                    </>
                                  );
                                })}
                              </Col>
                            </Row>
                          </>
                        )}
                      </div>
                    </Col>
                  ) : null}
                </Row>
                {isDragActive && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor:
                        folderParentUnitIds.includes(folderUnitId) ||
                        resultFromSearch
                          ? "rgba(250, 197, 201, 0.8)" // Light red if the condition is true
                          : "rgba(255, 255, 255, 0.8)", // Default white background
                      border: "2px dashed #cccccc",
                      borderRadius: "4px",
                      cursor:
                        folderParentUnitIds.includes(unitFolderUnitId) ||
                        resultFromSearch
                          ? "not-allowed" // Error cursor if the condition is true
                          : "default",
                    }}
                  >
                    <p style={{ fontSize: "24px", color: "#333" }}>
                      {folderParentUnitIds.includes(unitFolderUnitId) ||
                      resultFromSearch ? (
                        <GetLanguageString
                          props={"file_cannot_be_dropped_here"}
                        /> // Show error message
                      ) : (
                        <GetLanguageString
                          props={"drop_files_here_to_upload"}
                        /> // Default message
                      )}
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <div
                {...getRootProps()}
                style={{ position: "relative", marginTop: "20px" }}
              >
                <input {...getInputProps()} />
                <Row
                  style={{ border: "1px solid #E7EAEC", marginTop: 20 }}
                  className="m"
                >
                  <div
                    className="text-center"
                    style={{
                      fontStyle: "italic",
                      color: "#FF3333",
                      marginTop: 30,
                      marginBottom: 10,
                    }}
                  >
                    <h4>
                      {resultFromSearch ? (
                        <NoResult />
                      ) : (
                        <GetLanguageString
                          props={
                            CheckPermission("CanAddDocuments") &&
                            !folderParentUnitIds.includes(unitFolderUnitId)
                              ? "document_no_file_folder_present"
                              : "document_no_file_folder_present_only"
                          }
                        />
                      )}
                    </h4>
                  </div>
                  <div className="text-center" style={{ marginBottom: 30 }}>
                    {folderParentUnitIds.includes(unitFolderUnitId)
                      ? null
                      : !resultFromSearch
                      ? ButtonsComponent("")
                      : null}
                  </div>
                </Row>{" "}
                {isDragActive && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor:
                        folderParentUnitIds.includes(folderUnitId) ||
                        resultFromSearch
                          ? "rgba(250, 197, 201, 0.8)" // Light red if the condition is true
                          : "rgba(255, 255, 255, 0.8)", // Default white background
                      border: "2px dashed #cccccc",
                      borderRadius: "4px",
                      cursor:
                        folderParentUnitIds.includes(unitFolderUnitId) ||
                        resultFromSearch
                          ? "not-allowed" // Error cursor if the condition is true
                          : "default",
                    }}
                  >
                    <p style={{ fontSize: "24px", color: "#333" }}>
                      {folderParentUnitIds.includes(unitFolderUnitId) ||
                      resultFromSearch ? (
                        <GetLanguageString
                          props={"file_cannot_be_dropped_here"}
                        /> // Show error message
                      ) : (
                        <GetLanguageString
                          props={"drop_files_here_to_upload"}
                        /> // Default message
                      )}
                    </p>
                  </div>
                )}
              </div>
            )
          ) : (
            LoadingSpinner()
          )}
          {error && (
            <Alert color="danger" style={{ marginTop: "20px" }}>
              {error}
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default Documents;