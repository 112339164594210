import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { withRouter, useLocation } from "react-router-dom";

import { requestApi } from "../../actions/api";
import { GetLanguageString } from "../helper/Components";
import { DateValidater } from "../helper/Method";
import {
  LoadingSpinner,
  convertToDateString,
  convertUTCToLocalTime,
} from "../../constants/const";
import { ApiCourse } from "./ApiCourse";
import CourseSelectUnit from "./CourseSelectUnit";

const EditCourse = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const editValue = location.state.editValue;
  const [courseType, setCourseType] = useState([]);
  const [courseLocation, setCourseLocation] = useState([]);
  const [selectedCourseLocation, setSelectedCourseLocation] = useState({});
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const courseOptions = [];
  const [selectedCourseOptions, setSelectedCourseOptions] = useState({});
  const [courseValues, setCourseValues] = useState({
    startDate: null,
    address: "",
    description: "",
    endDate: null,
    unit: {},
    courseType: {},
    courseLocation: {},
  });
  const [selectedDates, setSelectedDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [errors, setErrors] = useState(courseValues);

  useEffect(() => {
    setLoading(true);
    requestApi("coursetype/")
      .fetchAll()
      .then((res) => {
        setCourseType(res.data);
      })
      .catch((err) => console.log(err));

    ApiCourse()
      .getCoursesLocations()
      .then((res) => {
        setCourseLocation(res.data);
      })
      .catch((err) => console.log(err));

    requestApi("course/")
      .fetchById(editValue)
      .then((res) => {
        setCourseValues({
          ...res.data,
          startDate: res.data.startDate
            ? new Date(convertUTCToLocalTime(res.data.startDate))
            : null,
          endDate: res.data.endDate
            ? new Date(convertUTCToLocalTime(res.data.endDate))
            : null,
        });
        setSelectedDates({
          startDate: res.data.startDate
            ? new Date(convertUTCToLocalTime(res.data.startDate))
            : null,
          endDate: res.data.endDate
            ? new Date(convertUTCToLocalTime(res.data.endDate))
            : null,
        });
        setSelectedCourseOptions(res.data.courseType);
        setSelectedCourseLocation(res.data.courseLocation);
        setLoading(false);
      });
  }, [editValue]);

  courseType.forEach((d) => courseOptions.push({ value: d.id, label: d.name }));

  const validate = () => {
    let temp = {};
    temp.startDate = selectedDates.startDate ? (
      ""
    ) : (
      <GetLanguageString props="courses_start_date_error_message" />
    );
    temp.endDate = selectedDates.endDate ? (
      ""
    ) : (
      <GetLanguageString props="courses_end_date_error_message" />
    );
    temp.courseType =
      Object.keys(courseValues.courseType).length === 0 ? "error" : "";

    temp.courseLocation =
      Object.keys(courseValues.courseLocation).length === 0 ? "error" : "";

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleChangeCourseType = (record) => {
    let temp = {};
    if (record !== null) temp = record;
    setCourseValues({
      ...courseValues,
      courseType: temp,
    });
    setErrors({
      ...errors,
      courseType: "",
    });
    setSelectedCourseOptions(record);
  };

  const handleChangeCourseLocation = (record) => {
    let temp = {};
    if (record !== null) temp = record;
    setSelectedCourseLocation(temp);
    setCourseValues({
      ...courseValues,
      courseLocation: temp,
    });
    setErrors({
      ...errors,
      courseLocation: "",
    });
  };

  const handleInputChangeStartDate = (value) => {
    if (DateValidater(value, selectedDates.endDate)) {
      setSelectedDates({
        ...selectedDates,
        startDate: value,
      });
      setErrors({
        startDate: "",
      });
    } else {
      setErrors({
        ...errors,
        startDate: (
          <GetLanguageString props="common_start_date_validation_message" />
        ),
      });
    }
  };

  const handleInputChangeEndDate = (value) => {
    if (DateValidater(selectedDates.startDate, value)) {
      setSelectedDates({
        ...selectedDates,
        endDate: value,
      });
      setErrors({
        endDate: "",
      });
    } else {
      setErrors({
        ...errors,
        endDate: (
          <GetLanguageString props="common_end_date_validation_message" />
        ),
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseValues({
      ...courseValues,
      [name]: value,
    });
    setErrors({
      [name]: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      courseValues.startDate = convertToDateString(selectedDates.startDate);
      courseValues.endDate = convertToDateString(selectedDates.endDate);
      requestApi("course/")
        .update(editValue, courseValues)
        .then((res) => {
          history.goBack();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      {!loading ? (
        <Form onSubmit={handleSubmit}>
          <div className="ibox ">
            <div className="ibox-title">
              <h3>{<GetLanguageString props="courses_edit_course" />}</h3>
            </div>
            <div className="ibox-content">
              <Row>
                {courseValues.address !== null &&
                courseValues.address !== "" ? (
                  <Col lg="6">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="courses_address" />}
                      </Label>
                      <Input
                        className="ur"
                        autoFocus
                        type="text"
                        name="address"
                        value={courseValues.address}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                ) : null}
                {courseValues.courseLocation.label != null ? (
                  <Col lg="3">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="courses_course_location" />}
                      </Label>
                      <Select
                        value={selectedCourseLocation}
                        options={courseLocation}
                        className="basic-single ur"
                        isClearable={true}
                        placeholder=""
                        classNamePrefix="select"
                        onChange={handleChangeCourseLocation}
                      />
                      {errors.courseLocation === "error" ? (
                        <h4 className="text-error">
                          {
                            <GetLanguageString props="courses_course_location_error_message" />
                          }
                        </h4>
                      ) : null}
                    </FormGroup>
                  </Col>
                ) : null}
                <Col lg={courseValues.courseLocation.label != null ? "3" : "6"}>
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="courses_course_type" />}
                    </Label>
                    <Select
                      value={selectedCourseOptions}
                      options={courseOptions}
                      className="basic-single ur"
                      isClearable={true}
                      placeholder="None"
                      classNamePrefix="select"
                      onChange={handleChangeCourseType}
                    />
                    {errors.courseType === "error" ? (
                      <h4 className="text-error">
                        {
                          <GetLanguageString props="courses_course_type_error_message" />
                        }
                      </h4>
                    ) : null}
                  </FormGroup>
                </Col>
                {courseValues.address !== null &&
                courseValues.address !== "" ? (
                  <>
                    <Col lg="6">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="courses_description" />}
                        </Label>
                        <Input
                          className="ur"
                          type="text"
                          name="description"
                          value={courseValues.description}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="courses_start_date" />}
                        </Label>
                        <DatePicker
                          showYearDropdown
                          placeholderText="dd/mm/yyyy"
                          className="form-control"
                          selected={selectedDates.startDate}
                          onChange={handleInputChangeStartDate}
                          dateFormat="dd/MM/yyyy"
                        />
                        {errors.startDate && (
                          <h4 className="text-error">{errors.startDate}</h4>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="courses_end_date" />}
                        </Label>
                        <DatePicker
                          showYearDropdown
                          placeholderText="dd/mm/yyyy"
                          className="form-control"
                          selected={selectedDates.endDate}
                          onChange={handleInputChangeEndDate}
                          dateFormat="dd/MM/yyyy"
                        />
                        {errors.endDate && (
                          <h4 className="text-error">{errors.endDate}</h4>
                        )}
                      </FormGroup>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col lg="3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="courses_start_date" />}
                        </Label>
                        <DatePicker
                          showYearDropdown
                          placeholderText="dd/mm/yyyy"
                          className="form-control"
                          selected={selectedDates.startDate}
                          onChange={handleInputChangeStartDate}
                          dateFormat="dd/MM/yyyy"
                        />
                        {errors.startDate && (
                          <h4 className="text-error">{errors.startDate}</h4>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="courses_end_date" />}
                        </Label>
                        <DatePicker
                          showYearDropdown
                          placeholderText="dd/mm/yyyy"
                          className="form-control"
                          selected={selectedDates.endDate}
                          onChange={handleInputChangeEndDate}
                          dateFormat="dd/MM/yyyy"
                        />
                        {errors.endDate && (
                          <h4 className="text-error">{errors.endDate}</h4>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="courses_description" />}
                        </Label>
                        <Input
                          className="ur"
                          type="text"
                          name="description"
                          value={courseValues.description}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </>
                )}
              </Row>
            </div>
          </div>
          <div className="ibox">
            <div className="ibox-title">
              <h3>{<GetLanguageString props="courses_select_unit" />}</h3>
            </div>
            <div className="ibox-content">
              <CourseSelectUnit
                setValues={setCourseValues}
                values={courseValues}
                unitId={courseValues.unit.value}
                excludeExUnit={excludeExUnit}
              />
              <Row>
                <Col md="4">
                  <FormGroup check>
                    <Label check for="excludeExUnit">
                      <Input
                        id="excludeExUnit"
                        type="checkbox"
                        name="excludeExUnit"
                        checked={!excludeExUnit}
                        value={!excludeExUnit}
                        onChange={handleExcludeExUnit}
                      />
                      {
                        <GetLanguageString props="member_search_member_ex_unit" />
                      }
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Button color="white" onClick={() => history.goBack()}>
                    {<GetLanguageString props="common_cancel" />}
                  </Button>
                  <Button color="primary" className="m-l-sm" type="submit">
                    {<GetLanguageString props="common_save_changes" />}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default withRouter(EditCourse);
