import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Table } from "reactstrap";
import swal from "sweetalert";

import { LoadingSpinner, isUrdu } from "../../../constants/const";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import AddDocumentType from "./AddDocumentType";
import { ApiDocumentType } from "./ApiDocumentType";
import EditDocumentType from "./EditDocumentType";

const DocumentType = ({ activeTab }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [documentType, setDocumentType] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [editValue, setEditValue] = useState();

  useEffect(() => {
    if (activeTab === "documentType") {
      setLoading(true);
      ApiDocumentType()
        .getDocumentTypes()
        .then((res) => {
          setDocumentType(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [activeTab, reload]);

  const handleOnDelete = (value) => {
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      icon: "warning",
      buttons: true,
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        ApiDocumentType()
          .deleteDocumentType(value)
          .then((res) => {
            swal({
              title: t("settings_document_type_delete_success"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: "OK",
            });
            setReload(!reload);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            swal({
              title: err,
              icon: "error",
              buttons: "OK",
            });
            setLoading(false);
          });
      }
    });
  };

  const handleOnEdit = (e) => {
    const { value } = e.target;
    setEditValue(value);
    setEditModal(true);
  };

  return (
    <div>
      {editModal ? (
        <EditDocumentType
          id={editValue}
          setEditModal={setEditModal}
          setReload={setReload}
          reload={reload}
        />
      ) : null}
      {addModal ? (
        <AddDocumentType
          setAddModal={setAddModal}
          setReload={setReload}
          reload={reload}
        />
      ) : null}
      <div className="ibox ">
        <div className="ibox-title">
          <h5>
            <GetLanguageString props="settings_document_types" />
          </h5>
          <Button
            color="white"
            size="sm"
            className={GetCustomStyles().btn_style}
            onClick={() => {
              setAddModal(true);
            }}
          >
            <i class="fa fa-plus"></i> &nbsp;
            {<GetLanguageString props="settings_document_type_add" />}
          </Button>
        </div>
        <div className="ibox-content">
          {!loading ? (
            documentType.length !== 0 ? (
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{<GetLanguageString props="common_name" />}</th>
                      <th>
                        {<GetLanguageString props="common_description" />}
                      </th>
                      <th className="text-right">
                        {<GetLanguageString props="common_action" />}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {documentType.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className={isUrdu(record.name) ? "ur" : ""}>
                            {record.name}
                          </td>
                          <td
                            className={isUrdu(record.description) ? "ur" : ""}
                          >
                            {record.description}
                          </td>
                          <td className="text-right">
                            <ButtonGroup>
                              <Button
                                color="white"
                                size="sm"
                                onClick={handleOnEdit}
                                value={record.id}
                              >
                                {<GetLanguageString props="common_edit" />}
                              </Button>
                              <Button
                                color="white"
                                size="sm"
                                onClick={() => handleOnDelete(record.id)}
                              >
                                {<GetLanguageString props="common_delete" />}
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <NoResult />
            )
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentType;
