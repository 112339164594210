import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Table } from "reactstrap";

import { requestApi } from "../../actions/api";
import {
  GetCustomStyles,
  GetFooTableCustomStyles,
  GetSelectedLanguage,
} from "../helper/Method";
import { GetLanguageString, NoResult } from "../helper/Components";
import SearchCourse from "./SearchCourse";
import { ApiUnit } from "../unit/ApiUnit";
import { ApiCourse } from "./ApiCourse";
import {
  LoadingSpinner,
  convertUTCToLocalTime,
  isUrdu,
} from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { IoAdd } from "react-icons/io5";

const initialValues = {
  courseType: "neutral",
  startDate: "descending",
  endDate: "neutral",
};

const Courses = ({ history, match }) => {
  const [loading, setLoading] = useState(false);
  const isIncharge = encryptStorage.getItem("canManage");
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const { t } = useTranslation();
  const [courses, setCourses] = useState([]);
  const [canAdd, setCanAdd] = useState();
  const [searchButton, setSearchButton] = useState(false);
  const [searchCourseModel, setSearchCourseModel] = useState({
    unit: { value: loggedInUnitId, lable: "" },
    startDate: null,
    endDate: null,
    isSubUnits: true,
    sortModel: {},
  });
  const [sortObject, setSortObject] = useState(initialValues);

  const handleSorting = (name) => {
    if (sortObject[name] === "neutral" || sortObject[name] === "descending") {
      setSortObject({
        ...initialValues,
        [name]: "ascending",
      });
    } else if (sortObject[name] === "ascending") {
      setSortObject({
        ...initialValues,
        [name]: "descending",
      });
    }
  };

  if (searchCourseModel.unit.value === 0) {
    ApiUnit()
      .getUnit(0, 0)
      .then((res) => {
        let temp = res.data.unitList[0];
        let temp2 = { value: temp.id, lable: temp.name };
        setSearchCourseModel({
          unit: temp2,
        });
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    ApiCourse()
      .checkCanAddCourse()
      .then((res) => {
        setCanAdd(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);

    let tempSortObject = {
      isCourseTypeDescending: sortObject.courseType === "descending",
      isCourseTypeAscending: sortObject.courseType === "ascending",
      isStartDateDescending: sortObject.startDate === "descending",
      isStartDateAscending: sortObject.startDate === "ascending",
      isEndDateDescending: sortObject.endDate === "descending",
      isEndDateAscending: sortObject.endDate === "ascending",
    };
    searchCourseModel.sortModel = tempSortObject;
    if (searchButton) {
      ApiCourse()
        .get(searchCourseModel)
        .then((res) => {
          setCourses(res.data.courseModel);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [searchCourseModel, sortObject, searchButton]);

  const handleOnDelete = (e) => {
    const { value } = e.target;
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        requestApi("course/")
          .delete(value)
          .then((res) => {
            setSearchCourseModel({
              ...searchCourseModel,
              "": "",
            });
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    });
  };

  const handleOnEdit = (e) => {
    const { value } = e.target;
    history.push({
      pathname: `${match.url}/editcourse/${value}`,
      state: {
        editValue: value,
      },
    });
  };

  const handleOnIncludeMember = (id, isPublished, areAhbabAllowed) => {
    // const { value } = e.target;
    history.push({
      pathname: `${match.url}/courseparticipant`,
      state: {
        courseId: id,
        published: isPublished,
        ahbabAllowed: areAhbabAllowed,
      },
    });
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="ibox ">
        <div className="ibox-title">
          <h5>{<GetLanguageString props="courses_search" />}</h5>
          {canAdd ? (
            <Button
              color="primary"
              size="sm"
              className={GetCustomStyles().btn_style}
              onClick={() =>
                history.push({
                  pathname: `${match.url}/addcourse`,
                  state: {
                    unitId: searchCourseModel.unit.value,
                  },
                })
              }
            >
              <IoAdd
                size={18}
                style={{ marginRight: 3, marginBottom: 1 }}
              />
              {<GetLanguageString props="courses_add_course" />}
            </Button>
          ) : null}
        </div>
        <div className="ibox-content">
          <SearchCourse
            unitId={searchCourseModel.unit.value}
            searchCourseModel={searchCourseModel}
            setSearchCourseModel={setSearchCourseModel}
            setSearchButton={setSearchButton}
          />
        </div>
      </div>
      {searchButton && (
        <div className="ibox ">
          <div className="ibox-title">
            <h5> {<GetLanguageString props="courses" />}</h5>
          </div>
          <div className="ibox-content">
            {!loading ? (
              courses.length !== 0 ? (
                <div className="table-responsive">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th
                          className="cursor-pointer text-center"
                          onClick={() => handleSorting("startDate")}
                        >
                          {<GetLanguageString props="courses_start_date" />}
                          <span
                            className={GetFooTableCustomStyles().span_style}
                          >
                            {sortObject.startDate === "neutral" ? (
                              <>
                                <i
                                  className={
                                    GetFooTableCustomStyles().up_down_style
                                  }
                                />
                                <i
                                  className={
                                    GetFooTableCustomStyles().down_up_style
                                  }
                                />
                              </>
                            ) : sortObject.startDate === "ascending" ? (
                              <i
                                className={GetFooTableCustomStyles().up_style}
                              />
                            ) : (
                              <i
                                className={GetFooTableCustomStyles().down_style}
                              />
                            )}
                          </span>
                        </th>
                        <th
                          className="cursor-pointer text-center"
                          onClick={() => handleSorting("endDate")}
                        >
                          {<GetLanguageString props="courses_end_date" />}
                          <span
                            className={GetFooTableCustomStyles().span_style}
                          >
                            {sortObject.endDate === "neutral" ? (
                              <>
                                <i
                                  className={
                                    GetFooTableCustomStyles().up_down_style
                                  }
                                />
                                <i
                                  className={
                                    GetFooTableCustomStyles().down_up_style
                                  }
                                />
                              </>
                            ) : sortObject.endDate === "ascending" ? (
                              <i
                                className={GetFooTableCustomStyles().up_style}
                              />
                            ) : (
                              <i
                                className={GetFooTableCustomStyles().down_style}
                              />
                            )}
                          </span>
                        </th>
                        <th style={{ width: "300px" }}>
                          {" "}
                          {<GetLanguageString props="courses_address" />}
                        </th>
                        <th style={{ width: "200px" }}>
                          {" "}
                          {<GetLanguageString props="courses_description" />}
                        </th>
                        <th
                          className="cursor-pointer"
                          onClick={() => handleSorting("courseType")}
                        >
                          {<GetLanguageString props="courses_course_type" />}
                          <span
                            className={GetFooTableCustomStyles().span_style}
                          >
                            {sortObject.courseType === "neutral" ? (
                              <>
                                <i
                                  className={
                                    GetFooTableCustomStyles().up_down_style
                                  }
                                />
                                <i
                                  className={
                                    GetFooTableCustomStyles().down_up_style
                                  }
                                />
                              </>
                            ) : sortObject.courseType === "ascending" ? (
                              <i
                                className={GetFooTableCustomStyles().up_style}
                              />
                            ) : (
                              <i
                                className={GetFooTableCustomStyles().down_style}
                              />
                            )}
                          </span>
                        </th>
                        <th>{<GetLanguageString props="courses_unit" />}</th>
                        {isIncharge ? (
                          <th className="text-center">
                            {<GetLanguageString props="common_action" />}
                          </th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {courses.map((record, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="text-center">
                              {moment(
                                convertUTCToLocalTime(record.startDate)
                              ).format("DD/MM/yyyy")}
                            </td>
                            <td className="text-center">
                              {record.endDate
                                ? moment(
                                    convertUTCToLocalTime(record.endDate)
                                  ).format("DD/MM/yyyy")
                                : "-"}
                            </td>
                            <td
                              className={
                                isUrdu(record.address) ||
                                isUrdu(record.courseLocation?.label)
                                  ? "ur"
                                  : ""
                              }
                            >
                              {record.address !== null && record.address !== ""
                                ? record.address
                                : record.courseLocation?.label}
                            </td>
                            <td className="ur">{record.description}</td>
                            <td className="ur">{record.courseType.label}</td>
                            <td className="ur">{record.unit.label}</td>
                            <td className="text-center">
                              <ButtonGroup>
                                {record.canMarkAttendance ? (
                                  moment(
                                    convertUTCToLocalTime(record.startDate)
                                  ).isBefore(Date.now()) ? (
                                    <Button
                                      color="white"
                                      size="sm"
                                      onClick={() =>
                                        handleOnIncludeMember(
                                          record.id,
                                          record.isPublished,
                                          record.areAhbabAllowed
                                        )
                                      }
                                      // value={record.id}
                                    >
                                      {
                                        <GetLanguageString props="common_attendance" />
                                      }
                                    </Button>
                                  ) : null
                                ) : null}
                                {record.isEditable ? (
                                  <Button
                                    color="white"
                                    size="sm"
                                    onClick={handleOnEdit}
                                    value={record.id}
                                  >
                                    {<GetLanguageString props="common_edit" />}
                                  </Button>
                                ) : null}
                                {record.isDeletable ? (
                                  <Button
                                    color="white"
                                    size="sm"
                                    onClick={handleOnDelete}
                                    value={record.id}
                                  >
                                    {
                                      <GetLanguageString props="common_delete" />
                                    }
                                  </Button>
                                ) : null}
                              </ButtonGroup>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <NoResult />
              )
            ) : (
              LoadingSpinner()
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(Courses);
