import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, withRouter } from "react-router-dom";
import { Button, FormGroup, Input, Label, Table } from "reactstrap";
import swal from "sweetalert";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingSpinner } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { GetLanguageString, NoResult } from "../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../helper/Method";
import { addMember } from "../member/ApiMember";
import { ApiUnitMember } from "./ApiUnit";
import SelectUnitForMember from "./SelectUnitForMember";

const MoveUnitMembers = ({ history }) => {
  const selectedLanguage = GetSelectedLanguage();
  const [loading, setLoading] = useState(false);
  // const [unitMember, setUnitMembers] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  // const [moveMemberResponsibilityModal, setMoveMemberResponsibilityModal] =
  //   useState(false);
  // const [moveMemberResponsibilities, setMoveMemberResponsibilities] =
  //   useState();
  const notify = (string) => toast.success(string);
  const unitTypeName = encryptStorage.getItem("unitTypeName");
  const { t } = useTranslation();
  const location = useLocation();
  const check = location.state.check;
  const unitId = parseInt(location.state.unitId);
  const [values, setValues] = useState({
    unit: { value: 0, lable: "", unitName: "" },
  });
  const [membersMoved, setMembersMoved] = useState(false);
  const [members, setMembers] = useState([]);
  const [responsibileMembers, setResponsibileMembers] = useState([]);
  const [isSubUnit, setIsSubUnit] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setLoading(true);
    addMember()
      .getUnitMember(unitId, isSubUnit)
      .then((res) => {
        let temp = [];
        let tempRes = [];
        res.data.forEach((record) => {
          if (record?.responsbilities?.length === 0) {
            temp.push({
              ...record,
              newUnitId: 0,
              status: false,
            });
          } else {
            tempRes.push({
              ...record,
              newUnitId: 0,
              status: true,
            });
          }
        });
        setMembers(temp);
        setResponsibileMembers(tempRes);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [unitId, membersMoved, isSubUnit]);

  const validate = () => {
    let temp = {};
    temp.unitId =
      values.unit.value === 0 ? (
        <GetLanguageString props="unit_unitmember_empty_unit_error_message" />
      ) : (
          check === "Outside"
            ? values.unit.unitName !== unitTypeName && unitTypeName === "Halqa"
            : null
        ) ? (
        <GetLanguageString props="unit_unitmember_halqa_unit_error_message" />
      ) : (
        ""
      );
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleChangeStatus = (status, index) => {
    let temp = [...members];
    temp[index].status = status;
    setMembers(temp);
  };

  const handleSubUnits = (status) => {
    setIsSubUnit(status);
  };

  let unitMembers = [];
  const handleMoveMembers = () => {
    if (validate()) {
      if (values.unit.value === unitId) {
        swal({
          title: t("unit_unitmember_already_exists_in_unit"),
          className: GetSelectedLanguage() === "en" ? "" : "ur",
          icon: "warning",
        });
      } else {
        let memberIds = [];
        members.forEach((member) => {
          if (member.status) {
            unitMembers.push({
              memberId: member.memberId,
              previousUnitId: member.unitId,
              status: member.status,
              newUnitId: values.unit.value,
            });
            memberIds.push(member.memberId);
          }
        });
        // setUnitMembers(unitMembers);
        if (unitMembers.length !== 0) {
          setLoading(true);
          setButtonDisable(true);
          // ApiResponsibility()
          //   .GetMovingMemberResponsibilities(memberIds)
          //   .then((res) => {
          //     if (res.data.length === 0) {
          ApiUnitMember()
            .moveUnitMembers({
              unitMemberModels: unitMembers,
              permission: check,
            })
            .then((res) => {
              if (res.data === "") {
                swal({
                  title: t("unit_unitmember_move_rufaqa_success"),
                  className: GetSelectedLanguage() === "en" ? "" : "ur",
                  icon: "success",
                });
              } else notify(res.data);
              setMembersMoved(!membersMoved);
              setLoading(false);
              setButtonDisable(false);
            })
            .catch((err) => {
              swal({
                title: "Error: " + err.response?.data || err,
                icon: "error",
                buttons: "OK",
              });
              console.log(err);
              setLoading(false);
              setButtonDisable(false);
            });
          // } else {
          //   setMoveMemberResponsibilities(res.data);
          //   setMoveMemberResponsibilityModal(true);
          //   setLoading(false);
          //   setButtonDisable(false);
          // }
          // });
        } else {
          swal({
            title: t("unit_unitmember_atleast_one_member_message"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "warning",
          });
        }
      }
    }
  };
  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <ToastContainer
        autoClose={3000}
        bodyClassName={selectedLanguage}
        closeOnClick
        pauseOnHover={false}
        draggable
      />
      {/* {moveMemberResponsibilityModal ? (
        <MoveMemberResponsibilities
          check={check}
          unitMembers={unitMember}
          moveMemberResponsibilities={moveMemberResponsibilities}
          setMoveMemberResponsibilityModal={setMoveMemberResponsibilityModal}
          setMembersMoved={setMembersMoved}
          membersMoved={membersMoved}
        />
      ) : null} */}
      <div className="ibox ">
        <div className="ibox-title">
          <h5>
            {<GetLanguageString props="unit_unitmember_move_member_to_unit" />}
          </h5>
          <Button
            color="primary"
            size="sm"
            className={GetCustomStyles().btn_style}
            onClick={() => history.goBack()}
          >
            {<GetLanguageString props="courses_participants_back" />}
          </Button>
        </div>
        <div className="ibox-content">
          <SelectUnitForMember
            values={values}
            setValues={setValues}
            setErrors={setErrors}
            unitId={unitId}
            check={check}
          />
          {errors.unitId ? (
            <h4 className="text-error">{errors.unitId}</h4>
          ) : null}
        </div>
      </div>
      <div className="ibox ">
        <div className="ibox-title">
          <h5>{<GetLanguageString props="unit_unitmember_move_member" />}</h5>
          <Button
            color="primary"
            size="sm"
            disabled={buttonDisable}
            className={GetCustomStyles().btn_style}
            onClick={() => handleMoveMembers()}
          >
            {<GetLanguageString props="unit_unitmember_move_member" />}
          </Button>
        </div>
        <div className="ibox-content">
          <FormGroup check className="m-b-md">
            <Label check for="IsIncharge">
              <Input
                id="IsIncharge"
                type="checkbox"
                name="isIncharge"
                className="big-checkbox"
                onChange={() => handleSubUnits(!isSubUnit)}
              />
              <h4 className="m-l-sm">
                {<GetLanguageString props="unit_unitmember_list_all_member" />}
              </h4>
            </Label>
          </FormGroup>

          {!loading ? (
            <div className="ibox">
              <div className="ibox-title">
                <h4>
                  {
                    <GetLanguageString
                      props={"unit_move_unit_non_responsibile_member_move_text"}
                    />
                  }
                </h4>
              </div>
              <div className="ibox-content">
                {members.length !== 0 ? (
                  <div className="table-responsive">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            {
                              <GetLanguageString props="unit_unitmember_select_member" />
                            }
                          </th>
                          <th>
                            {<GetLanguageString props="member_reg_number" />}
                          </th>
                          <th>{<GetLanguageString props="common_name" />}</th>
                          <th>
                            <GetLanguageString props="member_father_name" />
                          </th>
                          <th>
                            {<GetLanguageString props="member_address" />}
                          </th>
                          <th>{<GetLanguageString props="member_phone" />}</th>
                          <th>
                            {<GetLanguageString props="settings_member_type" />}
                          </th>
                          <th>
                            {<GetLanguageString props="unit_unit_table" />}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {members?.map((record, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <Input
                                  id={"select" + index}
                                  type="checkbox"
                                  name={"select" + index}
                                  checked={record.status}
                                  className={
                                    GetCustomStyles().movemembers_btn_style
                                  }
                                  onChange={() =>
                                    handleChangeStatus(!record.status, index)
                                  }
                                />
                              </td>
                              <td>{record.regNo}</td>
                              <td className="ur">{record.name}</td>
                              <td className="ur">{record.fatherName}</td>
                              <td className="ur">{record.currentAddress}</td>
                              <td>
                                {record.phone
                                  .split(",")
                                  .map((phoneNumber, index) => (
                                    <div key={index}>{phoneNumber}</div>
                                  ))}
                              </td>
                              <td className="ur">{record.memberTypeName}</td>
                              <td>
                                {
                                  <Button
                                    color="info"
                                    className="label label-info"
                                  >
                                    <h5 className="ur">{record.unitName}</h5>
                                  </Button>
                                }
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <NoResult />
                )}
              </div>
            </div>
          ) : (
            LoadingSpinner()
          )}
          {!loading ? (
            responsibileMembers.length !== 0 ? (
              <div className="ibox">
                <div className="ibox-title">
                  <h4 className="text-error">
                    {
                      <GetLanguageString
                        props={"unit_move_unit_responsibile_member_move_text"}
                      />
                    }
                  </h4>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            {<GetLanguageString props="member_reg_number" />}
                          </th>
                          <th>{<GetLanguageString props="common_name" />}</th>
                          <th>
                            <GetLanguageString props="member_father_name" />
                          </th>
                          <th>
                            {<GetLanguageString props="member_address" />}
                          </th>
                          <th>{<GetLanguageString props="member_phone" />}</th>
                          <th>
                            {<GetLanguageString props="settings_member_type" />}
                          </th>
                          <th>
                            {
                              <GetLanguageString props="unit_responsibilities" />
                            }
                          </th>
                          <th>
                            {<GetLanguageString props="unit_unit_table" />}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {responsibileMembers?.map((record, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{record.regNo}</td>
                              <td className="ur">{record.name}</td>
                              <td className="ur">{record.fatherName}</td>
                              <td className="ur">{record.currentAddress}</td>
                              <td>
                                {record.phone
                                  .split(",")
                                  .map((phoneNumber, index) => (
                                    <div key={index}>{phoneNumber}</div>
                                  ))}
                              </td>
                              <td className="ur">{record.memberTypeName}</td>
                              <td>
                                {record.responsbilities.map((item, index) => {
                                  return (
                                    <Button
                                      color="info"
                                      className="label label-info"
                                    >
                                      <h5 className="ur">{item}</h5>
                                    </Button>
                                  );
                                })}
                              </td>
                              <td>
                                {
                                  <Button
                                    color="info"
                                    className="label label-info"
                                  >
                                    <h5 className="ur">{record.unitName}</h5>
                                  </Button>
                                }
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            ) : (
              <NoResult />
            )
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(MoveUnitMembers);
